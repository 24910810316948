<template>
    <div class="app-subscriber-login-user-flow-email">
        <div>
            <app-subscriber-header :title="loginTitle" />
            <div class="app-subscriber-login-user-flow-email__contents">
                <form method="post" autocomplete="off" novalidate>
                    <app-subscriber-label :text="emailLabel"></app-subscriber-label>
                    <tip-text-input
                        v-model="localUserEmail"
                        :name="'username'"
                        :placeholder="usernamePlaceholder"
                        :type="'email'"
                        :invalid="shouldShowEmailValidationError"
                        :validation-message="emailValidationErrorMessage"
                    />
                    <tip-button
                        id="emailSubmitButton"
                        :type="buttonType"
                        :disabled="preventSubmit"
                        :loading="localIsLoading"
                        @click="userEmailSubmit"
                    >
                        {{ buttonTextNext }}
                        <template v-slot:loading>
                            <font-awesome-icon class="fa-spin" :icon="['far', 'spinner']" />
                        </template>
                    </tip-button>
                </form>
            </div>
        </div>
        <app-subscriber-footer />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import logrocket from '@/lib/logrocket';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import router from '@/router';
import AppSubscriberHeader from '@/components/AppSubscriber/AppSubscriberHeader';
import AppSubscriberFooter from '@/components/AppSubscriber/AppSubscriberFooter';
import AppSubscriberLabel from '@/components/AppSubscriber/common/AppSubscriberLabel';

export default {
    name: 'AppSubscriberLoginUserFlowEmail',
    components: {
        AppSubscriberHeader,
        AppSubscriberFooter,
        AppSubscriberLabel
    },
    data() {
        return {
            localUserEmail: '',
            shouldShowEmailValidationError: false,
            localIsLoading: false
        };
    },
    computed: {
        ...mapState('login', [
            'isLoading',
            'loginProcessInitialized',
            'oidcReturnUrl',
            'isAppLoginFlowSsoOnly',
            'isUserLoginFlowSso',
            'isUserLoginFlowPassword',
            'isUserEmailValid'
        ]),
        loginTitle() {
            return langConsts.titleLogin;
        },
        usernamePlaceholder() {
            return langConsts.titleEnterEmail;
        },
        emailLabel() {
            return langConsts.labelEmail;
        },
        emailValidationErrorMessage() {
            return langConsts.emailValidationError;
        },
        buttonTextNext() {
            return langConsts.buttonTextNext;
        },
        preventSubmit() {
            return !this.localUserEmail || this.localIsLoading;
        },
        buttonType() {
            return this.localIsLoading ? 'secondary' : 'primary';
        }
    },
    watch: {
        localUserEmail(email) {
            if (!email) {
                this.shouldShowEmailValidationError = false;
            }
        }
    },
    async created() {
        if (this.loginProcessInitialized === false) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
            return;
        }
        if (this.isAppLoginFlowSsoOnly === true) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
            return;
        }
    },
    methods: {
        ...mapActions('login', ['initializeLoginByUserFlow', 'setErrorLogin']),

        async userEmailSubmit() {
            this.localIsLoading = true;
            this.shouldShowEmailValidationError = false;

            await this.initializeLoginByUserFlow({
                userEmail: this.localUserEmail,
                oidcReturnUrl: this.oidcReturnUrl
            });

            // verify email
            if (this.isUserEmailValid) {
                logrocket.addContext(this.localUserEmail);

                if (this.isUserLoginFlowSso === false && this.isUserLoginFlowPassword === false) {
                    await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
                } else {
                    router.replace(`${loginConsts.urls.baseSubscriberAppUrl}/${loginConsts.urls.urlPathLoginAuthenticate}`);
                }
            } else {
                this.shouldShowEmailValidationError = true;
                this.localIsLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.app-subscriber-login-user-flow-email {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
    &--loading {
        margin: auto;
    }
    &__contents {
        margin: 1.5rem 4.375rem 0 4.375rem;
    }
}
</style>
